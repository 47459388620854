import {useState} from "react";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import './index.css';

export default function Attachments(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [documentNumber, setDocumentNumber] = useState(0);
    const { sessionStart } = props;
    const pdfArray = sessionStart?.exam?.configuration?.attachments || [];

    function onDocumentLoadSuccess( { numPages }) {
        setNumPages(numPages);
    }

    const goToPrevPage = () => {
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
        const pageInput = document.getElementById('spAttachmentInput');
        pageInput.value = "";
    };

    const goToNextPage = () => {
        setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
        const pageInput = document.getElementById('spAttachmentInput');
        pageInput.value = "";
    };

    const files = [];
    pdfArray.forEach(pdf => {
        files.push(pdf.attachmentUrl);
    });

    const createDocumentButtons = () => {
        return pdfArray.map((fileData, index) => {
            return <button key={fileData.attachmentAwsTitle} onClick={() => {setDocumentNumber(index); setPageNumber(1)}}>{fileData.attachmentAwsTitle}</button>
        });
    };

    const handleJumpToPage = (event) => {
        let inputPageNumber = event.target.value.replace(/\D/g, '');
        const parsedNumber = parseInt(inputPageNumber);
        if (parsedNumber >= 1 && parsedNumber <= numPages) {
            setPageNumber(parsedNumber);
        }
    };

    if (pdfArray.length > 0) {
        return (
            <main id="main-content" className="page">
                {createDocumentButtons()}
                <Document file={files[documentNumber]} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page className='spPdfPage' pageNumber={pageNumber} />
                </Document>
                <nav>
                    <button onClick={goToPrevPage}>Previous</button>
                    <button onClick={goToNextPage}>Next</button>
                    <p>
                        Page <input id="spAttachmentInput" type="number" placeholder={pageNumber} max={numPages} min="1" onChange={handleJumpToPage}/> of {numPages}
                    </p>
                </nav>
            </main>
        );
    } else {
        return (
            <main id="main-content" className="page">
                No attachments found for this exam.
            </main>
        );
    }
}