export let config = {
    //apiUrl: 'http://localhost:8011/v1',
    apiUrl: 'https://api.sub.smarterproctoring.com/v1',
    extensionId: 'njmamkocphdenkffaliamlfabafhmpjl',
    supportScriptUrl: 'https://help.dev.smarterservices.com/embed/in-app-widget.js',
    liveswitchApplicationId: '0b2006d5-652b-4292-afc0-2713abb89a75',
    classCalcApiKey: 'sqlKeyMsCJsqCUPny8jIm27ZeJrPSrU_',
    ldApiKey: '59edfdfd082f980b153ac148'
}

if (process.env.REACT_APP_ENVIRONMENT === 'sub') {
    config.apiUrl = 'https://api.sub.smarterproctoring.com/v1'
}

if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    config.apiUrl = 'https://api.dev.smarterproctoring.com/v1'
}

if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    config.apiUrl = 'http://localhost:8011/v1'
}

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    config.apiUrl = 'https://api.smarterproctoring.com/v1';
    config.supportScriptUrl = 'https://help.smarterservices.com/embed/in-app-widget.js';
    config.extensionId = 'niffbjhghfgccacnebkhbpmgbnhocapg';
    config.liveswitchApplicationId = '79452c0f-28e2-4e00-8600-bb5fc06e4ee9';
    config.ldApiKey = '55b25a2f133767270f000092';
}
