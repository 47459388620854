import screenShareGif from "../../img/screen-sharing.gif";
import { useTranslation } from 'react-i18next';
import "./index.css";

export default function ShareScreen() {
  const { t:translation } = useTranslation();
  return (
    <div className="stepper-container">
      <aside className='left'></aside>
      <main>
        <header className="sp-heading">{translation('screen_sharing.title')}</header>
        <div className="twin-pane-container">
          <section className="leftPane">
          <div className="body">
                    <p>{translation('screen_sharing.left_pane.content.0')}</p>

                    <img
                        className='screen_share_example'
                        src = {screenShareGif}
                        alt = 'screen share example'
                    />

                    <h2>{translation('screen_sharing.left_pane.steps.0')}</h2>
                    <p>{translation('screen_sharing.left_pane.step_1.0')}</p>

                    <h2>{translation('screen_sharing.left_pane.steps.1')}</h2>
                    <p>{translation('screen_sharing.left_pane.step_2.0')}</p>

                    <h2>{translation('screen_sharing.left_pane.steps.2')}</h2>
                    <p>{translation('screen_sharing.left_pane.step_3.0')}</p>
                    <p>{translation('screen_sharing.left_pane.step_3.1')}</p>
                </div>
          </section>

          <section className="rightPane">
            <div className="column">
              <button className="btn">{translation('screen_sharing.right_pane.share_button')}</button>
            </div>
          </section>
        </div>
      </main>
      <aside className='right'></aside>
    </div>
  )
}