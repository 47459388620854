import { useEffect } from "react";

export default function Zendesk(props) {

    const {sessionStart} = props;

    useEffect(()=> {
        const renderZDChat = () => {
            const zdScript = document.querySelector('#ze-snippet');

            if(!zdScript && sessionStart.user) {
                let zdc = document.createElement('script');
                zdc.id = 'ze-snippet';
                zdc.type = 'text/javascript';
                zdc.async = true;
                zdc.src = 'https://static.zdassets.com/ekr/snippet.js?key=a3206868-bdac-4af5-a921-ee51087a581e';

                zdc.onload = function() {
                    window.zE(function() {
                        window.zE.identify({
                            name: `${sessionStart.user?.firstName || ''} ${sessionStart.user?.lastName || ''}`,
                            email: sessionStart.user?.email || '',
                            organization: ''
                        });
                        window.zE('webWidget', 'updateSettings', {
                            webWidget: {
                                offset: {
                                    vertical: '44px'
                                }
                            }
                        });
                    });
                }

                document.body.appendChild(zdc);
            }

            return null;
        };

        renderZDChat();
    }, [sessionStart]);
}
