import { useEffect } from "react";
import { config } from "../../config";

export default function Salesforce(props) {
  useEffect(()=> {
    const { sessionStart, params, decodedToken, token } = props;
    const { installSid, examSid, sessionSid } = params;

    const optionsDefined = !!window.inAppHelpOptions;

    if (!optionsDefined && Object.keys(sessionStart).length > 0) {
      window.inAppHelpOptions = {
        token: token,
        application_sid: 'AP6800b9e2e1794dbbbf6ad866194821d3',
        context: 'in_session',
        sids: {
          course_sid: decodedToken.courseSid,
          enrollment_sid: decodedToken.enrollmentSid,
          exam_sid: examSid,
          install_sid: installSid,
          user_sid: decodedToken.userSid,
          session_sid: sessionSid
        }
      }

      const script = document.createElement('script');
      script.src = config.supportScriptUrl;
      script.type = 'text/javascript';
      script.onload = () => window.initHelpCenter();

      document.body.appendChild(script);
    }
  }, [props]);
}