import {useTranslation} from "react-i18next";
import './index.css';

export default function SessionDetails(props) {
    const { t: translate } = useTranslation();
    const translation = translate('session_details');
    const { sessionStart, studentNotes } = props;
    return (
        <main tabIndex="0" id="main-content" className="page">
            <header className="sp-heading">
                <div className="wrap">
                    <h1 className="page__title">{translation.heading}</h1>
                </div>
            </header>
            <div className="wrap">
                <ul className="data">
                    <li>
                        <strong className="subtitle">{translation.user.name}</strong>
                        {} {sessionStart?.user?.firstName} {sessionStart?.user?.lastName}
                    </li>
                    <li>
                        <strong className="subtitle">{translation.exam.title}</strong>
                        {} {sessionStart?.exam?.title}
                    </li>
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.duration}</strong>
                        {} {sessionStart?.exam?.configuration?.duration}
                    </li>
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.student_notes}</strong>
                        <p className="scroll-box">{studentNotes}</p>
                    </li>
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_bathroom_break}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedBathroomBreak ?  translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedBathroomBreakInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_bathroom_break_info}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedBathroomBreakInformation}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_blank_paper}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedBlankPaper ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedBlankPaper &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_blank_paper_info}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedBlankPaper}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_calculator}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedCalculator ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedCalculatorInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_calculator_info}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedCalculatorInformation}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_dictionary}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedDictionary ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedDictionaryInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_dictionary_information}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedDictionaryInformation}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_earplugs}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedEarplugs ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedEarplugsInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_earplug_information}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedEarplugsInformation}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_formula_sheet}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedFormulaSheet ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedFormulaSheetInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_formula_sheet_information}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedFormulaSheetInformation}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_notes}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedNotes ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedNotesInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_notes_information}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedNotesInformation}
                    </li>
                    }
                    { sessionStart?.exam?.configuration?.permittedOtherItems &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.other_items_information}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedOtherItems}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_textbook}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedTextbook ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedTextbookInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_textbook_information}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedTextbookInformation}
                    </li>
                    }
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_websites}</strong>
                        {} {`${sessionStart?.exam?.configuration?.permittedWebsites ? translation.true : '-'}`}
                    </li>
                    { sessionStart?.exam?.configuration?.permittedWebsitesInformation &&
                    <li>
                        <strong className="subtitle">{translation.exam.configuration.permitted_websites_information}</strong>
                        {} {sessionStart?.exam?.configuration?.permittedWebsitesInformation}
                    </li>
                    }
                </ul>
            </div>
        </main>
    );
}